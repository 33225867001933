import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import UserDetail from '../components/UserDetail';
import { Link } from 'react-router-dom';

const clutchProjects = [
  {
    title: "Clutch Logistics",
    subtitle: "Integrated Transport Management Solution",
    image: './images/clutch_logistics.png',
    description: "Clutch Logistics combines a web application with the Clutch Driver App to deliver a powerful, integrated logistics solution. This software is tailored for exporters and large-scale logistics operations, helping you efficiently manage assets, transport scheduling, and fleet monitoring.",
    internalLink: "/logistics"
  },
  {
    title: "Clutch Booking",
    subtitle: "Smart Scheduling Platform",
    image: './images/booking_img.png',
    description: "Clutch Booking is designed to optimize booking management for time-sensitive industries. It helps site managers create customizable booking slots, track availability, and improve scheduling efficiency.",
    internalLink: "/bookings"
  },
  {
    title: "Clutch Driver Application",
    subtitle: "Mobile Fleet Management Tool",
    image: "/images/Mobile_clutch.png",
    description: "The Clutch Driver App is a mobile solution built for drivers in the logistics and grain transport industries. It simplifies daily tasks, ensures timely deliveries, and keeps drivers connected to dispatch teams in real time.",
    
  }
];

const features = [
  {
    "title": "Transport Scheduling",
    "description": "Organize routes and schedules seamlessly to meet deadlines."
  },
  {
    "title": "Fleet Monitoring",
    "description": "Real-time visibility of vehicle locations and statuses for optimized logistics."
  },
  {
    "title": "Integration with Clutch Driver App",
    "description": "Ensure smooth communication between dispatch teams and drivers."
  },
  {
    "title": "GPS Tracking",
    "description": "Real-time location updates for better operational oversight."
  },
  {
    "title": "Web-Based Application",
    "description": "Plan and monitor operations from anywhere with internet access."
  },
  {
    "title": "Custom Booking Slots",
    "description": "Create and manage site-specific slots for drop-offs and pick-ups."
  },
  {
    "title": "Integrated Scheduling",
    "description": "Sync seamlessly with Clutch Logistics for a unified operations experience."
  },
  {
    "title": "Real-Time Availability Tracking",
    "description": "Always know the status of bookings to avoid over-scheduling."
  },
  {
    "title": "Improved Communication",
    "description": "Facilitate seamless coordination across scheduling, transport, and asset management."
  },
  {
    "title": "Real-Time Job Assignments",
    "description": "Drivers receive clear, up-to-date job information instantly."
  },
  {
    "title": "Delivery Status Updates",
    "description": "Keep dispatch informed with delivery progress and completion statuses."
  },
  {
    "title": "Route Tracking",
    "description": "GPS integration for accurate and efficient navigation."
  },
  {
    "title": "Easy Reporting",
    "description": "Submit trip reports, incidents, or updates directly through the app."
  },
  {
    "title": "Mobile-Friendly Interface",
    "description": "Designed for ease of use, ensuring drivers can focus on their jobs."
  }
];

const FeatureBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 3000); // Change feature every 3 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full bg-gray-50 py-4 mb-14 rounded-3xl overflow-hidden">
      <div className="max-w-7xl mx-auto px-4">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <h3 className="text-lg md:text-xl font-bold text-accent mb-2">
              {features[currentIndex].title}
            </h3>
            <p className="text-sm md:text-base text-black">
              {features[currentIndex].description}
            </p>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

const Services = () => {
  return (
    <main className="py-14">
    <section className="py-32 px-4 sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-8"
        >
          <h2 className="text-2xl md:text-4xl font-bold mb-4">Revolutionize Your Logistics with Clutch IT Solutions</h2>
          <p className="text-sm md:text-base text-gray-600 max-w-2xl mx-auto">
            Streamline operations, simplify bookings, and empower drivers with our innovative software suite.
          </p>
        </motion.div>

        <FeatureBanner />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {clutchProjects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-3xl overflow-hidden shadow-lg"
            >
              <div className="aspect-[16/9]">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-8">
                <div className="flex justify-between items-center mb-2">
                  <div>
                    <h3 className="text-2xl font-bold text-gray-900">{project.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">{project.subtitle}</p>
                  </div>
                  
                  {project.internalLink && (
                    <Link 
                      to={project.internalLink}
                      className="w-12 h-12 bg-accent/80 text-white rounded-full flex items-center justify-center hover:bg-accent/70 transition-colors"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </Link>
                  )}
                </div>
                <p className="text-gray-600 leading-relaxed mb-4">{project.description}</p>
                
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
    <UserDetail />
    </main>
  );
};

export default Services; 