import React from 'react';
import { motion as m } from 'framer-motion';


const Bookings = () => {

  const products = [
    {
      image: './images/booking_img.png',
      name: 'Clutch Booking',
      role: 'Platform',
      thumbnail: '/images/logo_booking.png'
    },
    {
      image: './images/Product-1.png',
      thumbnail: '/images/booking_cal.png'
    },
    {
      image: '/images/Product-3.jpg',
      thumbnail: '/images/booking_prc.png'
    }
  ];

  return (
    <div className="min-h-screen bg-white px-4 py-24 md:py-24">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="grid grid-cols-1 py-10 lg:grid-cols-2 gap-16 items-center">
          <div>
            <m.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-6xl font-extrabold mb-8 text-gray-800"
            >
              Clutch
            </m.h1>
            <m.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-6xl font-extrabold text-gray-800"
            >
              Booking
            </m.h1>
          </div>
          
          <m.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="text-xl leading-relaxed text-gray-600"
          >
         Clutch Booking is a robust and versatile software solution designed to streamline booking management for time-sensitive
          industries and site operations. Whether managing drop-offs, pick-ups, or appointment slots, it provides the tools needed to ensure
           efficient coordination and seamless scheduling.
          </m.p>
        </div>

        {/* Product Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Main Image */}
          <div className="relative">
            <m.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.6 }}
              className="relative aspect-[3/4] bg-white rounded-lg overflow-hidden"
            >
              <img 
                src={products[0].image} 
                alt={products[0].name}
                className="h-full object-cover object-left"
              />
              <button className="absolute right-4 bottom-4 w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center hover:bg-primary/90 transition-colors">
                <span>→</span>
              </button>
            </m.div>
            
            {/* Name and Role */}
            <m.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="mt-4"
            >
              <h3 className="text-xl text-gray-900">{products[0].name}</h3>
              <p className="text-gray-600">{products[0].role}</p>
            </m.div>
          </div>

          {/* Right Side Content */}
          <div className="space-y-16">
            {/* Thumbnails */}
            <m.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="flex gap-4"
            >
              {products.map((product, index) => (
                <div key={index} className="w-26 h-24 bg-gray-100 rounded-lg overflow-hidden">
                  <img 
                    src={product.thumbnail}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </m.div>

            {/* Bottom Text */}
            <m.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
              className="text-xl leading-relaxed text-right text-gray-600"
            >
              The platform empowers site managers to create customized, categorized booking slots, track availability
               in real-time, and optimize workflows to prevent bottlenecks and minimize delays. Its intuitive interface 
               simplifies complex scheduling tasks, making it an essential tool for industries where timing and precision are critical.
            </m.p>

            <m.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.1 }}
              className="text-xl leading-relaxed text-right text-gray-600"
            >
              When integrated with the Clutch Logistics platform, Clutch Booking delivers a comprehensive solution
               by aligning scheduling with transport and asset management. This synergy ensures smooth operations, improved communication,
                and enhanced productivity across all facets of your business.
            </m.p>

            {/* Request Demo Button */}
            <m.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.2 }}
              className="flex justify-end"
            >
              <a href="https://clutch-support.atlassian.net/servicedesk/customer/portal/7/group/13/create/44" target="_blank" rel="noopener noreferrer">
            <button 
              className="btn-primary flex items-center gap-2"
            >
              Request a Demo
            </button>
            </a>
            </m.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings; 