import React from 'react';
import { motion } from 'framer-motion';

const SupportPage = () => {
  return (
    <section className="min-h-screen flex flex-col items-center bg-gray-100 py-28 px-8">
      <motion.div
        className="max-w-3xl text-left"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold mb-6">Support</h1>
        <p className="text-lg text-gray-700 mb-8">
          At Clutch IT, we are committed to providing top-notch support to ensure your experience with our software solutions is seamless and efficient. Whether you're an exporter, logistics provider, or receival site, our team is here to assist you every step of the way.
        </p>

        <h2 className="text-2xl font-semibold mb-6">How Can We Help You?</h2>

        <motion.div
          className="mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h3 className="text-xl font-semibold mb-2">Clutch Application Troubleshooting Guide</h3>
          <p className="text-gray-700 mb-4">
            Start with our comprehensive troubleshooting guide to quickly resolve common issues and optimize your experience. The guide includes step-by-step solutions for technical challenges and tips for effective usage.
          </p>
          <a href="https://clutch-support.atlassian.net/servicedesk/customer/portal/7/article/1179906" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Access the Troubleshooting Guide</a>
        </motion.div>

        <motion.div
          className="mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <h3 className="text-xl font-semibold mb-2">Submit a Ticket</h3>
          <p className="text-gray-700 mb-4">
            For complex issues, submit a support ticket, and our team will get back to you promptly. Include detailed information about your issue to help us resolve it faster.
          </p>
          <a href="https://clutch-support.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            <button
              className="btn-primary px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-all"
            >
              Go to Clutch Help Center
            </button>
          </a>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default SupportPage; 