import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const testimonials = [
    {
      name: "Rose M.",
      role: "General Manager Operations",
      industry: "Agriculture and Logistics",
      employees: "11 to 50 Employees",
      date: "Nov. 15, 2024",
      rating: 5,
      review: "Clutch Logistics has transformed how we manage transport and fleet operations. The integration with the Clutch Driver App ensures seamless communication between our team and drivers, allowing us to track progress in real-time and meet deadlines without hassle.",
      logo: "/images/mahonys_logo.jpg",
    },
    {
      name: "Alec S.",
      role: "General Manager Import & Export",
      industry: "Logistics and Supply Chain",
      employees: "11 to 50 Employees",
      date: "Nov. 20, 2024",
      rating: 5,
      review: "The Clutch Booking system has been a game-changer for managing our site scheduling. Creating custom booking slots and tracking availability in real-time has helped us improve coordination and reduce site congestion, saving us time and effort.",
      logo: "/images/mahonys_logo.jpg",
    },
    {
      name: "Ashley D.",
      role: "Company Secretary",
      industry: "International Trade & Logistics",
      employees: "11 to 50 Employees",
      date: "Dec. 10, 2024",
      rating: 5,
      review: "The Clutch Driver App has simplified driver operations like never before. With real-time job assignments, GPS-enabled route tracking, and easy reporting features, our drivers are more efficient, and communication with dispatch has greatly improved.",
      logo: "/images/grainpac_logo.png",
    }
  ];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="py-16 md:pb-8 md:pt-0">
      <div className="text-center mb-8">
        <h2 className="text-xl md:text-3xl font-bold text-gray-900 mb-2">
          What Our Clients Say
        </h2>
        <p className="text-xs md:text-base text-gray-600 max-w-2xl mx-auto px-4">
          Discover how businesses across Australia are transforming their logistics operations with our solutions
        </p>
      </div>

      <div className="relative">
        {/* Desktop View */}
        <div className="hidden md:flex md:flex-row md:flex-wrap md:justify-center md:gap-4 p-4">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} index={index} />
          ))}
        </div>

        {/* Mobile View */}
        <div className="md:hidden relative flex items-center justify-center px-4">
          <button 
            onClick={prevTestimonial}
            className="absolute left-0 z-10 bg-white/80 rounded-full p-2 shadow-lg"
            aria-label="Previous testimonial"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <TestimonialCard 
                testimonial={testimonials[currentIndex]} 
                index={currentIndex}
              />
            </motion.div>
          </AnimatePresence>

          <button 
            onClick={nextTestimonial}
            className="absolute right-0 z-10 bg-white/80 rounded-full p-2 shadow-lg"
            aria-label="Next testimonial"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ testimonial, index }) => (
  <motion.div
    key={index}
    className="bg-gray-50 shadow-lg rounded-lg p-3 md:p-6 w-[300px] md:w-80"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    whileHover={{ 
      scale: 1.03,
      boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
    }}
    transition={{ 
      duration: 0.5, 
      delay: index * 0.2,
      type: "spring",
      stiffness: 300
    }}
  >
    <div className="flex justify-between items-center mb-3 md:mb-4">
      <img src={testimonial.logo} alt="logo" className="h-6 md:h-8" />
      <span className="text-xs md:text-sm text-gray-500">{testimonial.date}</span>
    </div>
    
    <p className="text-sm md:text-base text-gray-700 mb-3 md:mb-4">"{testimonial.review}"</p>
    <div className="text-xs md:text-sm text-gray-600">
      <p className="font-medium">{testimonial.name}</p>
      <p>{testimonial.role}</p>
      <p>{testimonial.industry}</p>
      <p>{testimonial.employees}</p>
    </div>
  </motion.div>
);

export default Testimonials; 