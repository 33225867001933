import React from 'react';
import { motion } from 'framer-motion';


const sections = [
  {
    title: "1. Acceptance of Terms",
    content: [
      "1.1 By registering for, accessing, or using our Services, you agree to comply with these Terms and our Privacy Policy.",
      "1.2 You confirm that you are at least 18 years old or have the legal capacity to enter into this agreement.",
      "1.3 Your use of the Services is subject to applicable fees as specified in your subscription or contract."
    ]
  },
  {
    title: "2. Changes to the Terms",
    content: [
      "2.1 Clutch IT reserves the right to modify these Terms at any time. Updates will be communicated via email or through the Platform.",
      "2.2 Continued use of the Services after modifications constitutes your acceptance of the revised Terms."
    ]
  },
  {
    title: "3. Business Ownership and Intellectual Property",
    content: [
      "3.1 Ownership: MAHONYS SOFTWARE BD PTY LTD owns all rights, title, and interest in and to the Platform, including all associated intellectual property.",
      "3.2 User License: We grant you a limited, non-exclusive, non-transferable, and revocable license to use the Services as described in these Terms.",
      "3.3 Prohibited Use: You may not copy, distribute, modify, reverse engineer, or otherwise misuse the Services or any of Clutch IT's intellectual property."
    ]
  },
  {
    title: "4. Platform Use and Responsibilities",
    content: [
      "4.1 Authorized Use: The Services may only be used for lawful purposes in accordance with these Terms. You are responsible for compliance with all applicable laws while using the Services.",
      "4.2 Account Security: You are responsible for maintaining the confidentiality of your login credentials and any activities conducted through your account.",
      "4.3 Prohibited Actions: You must not:",
      "• Interfere with or disrupt the Platform's functionality.",
      "• Use the Platform for illegal or fraudulent activities.",
      "• Attempt to access the Platform's source code or develop competing solutions based on the Platform."
    ]
  },
  {
    title: "5. Payments and Fees",
    content: [
      "5.1 You agree to pay all fees associated with your subscription or usage of the Platform. Fees will be billed according to your contract or subscription terms.",
      "5.2 Late payments may result in suspension or termination of access to the Services.",
      "5.3 You are responsible for all applicable taxes, duties, or charges."
    ]
  },
  {
    title: "6. Privacy and Data Handling",
    content: [
      "6.1 Privacy Policy: Clutch IT collects and processes your data in accordance with our Privacy Policy. By using the Services, you consent to our data handling practices.",
      "6.2 Data Ownership: You retain ownership of any data you upload to the Platform. Clutch IT only uses this data as necessary to provide the Services.",
      "6.3 Data Security: While we implement industry-standard measures to protect your data, we do not guarantee complete security."
    ]
  },
  {
    title: "7. Limitation of Liability",
    content: [
      "7.1 To the fullest extent permitted by law, Clutch IT is not liable for:",
      "• Indirect, incidental, or consequential damages.",
      "• Loss of data, revenue, or profits arising from your use of the Platform.",
      "7.2 In no event shall Clutch IT's liability exceed the fees paid by you in the 12 months prior to the incident giving rise to the claim."
    ]
  },
  {
    title: "8. Confidentiality",
    content: [
      "8.1 Both parties agree to keep confidential all non-public information disclosed in relation to these Terms.",
      "8.2 This confidentiality obligation does not apply to information that:",
      "• Is already public knowledge.",
      "• Was lawfully obtained from a third party.",
      "• Is required to be disclosed by law."
    ]
  },
  {
    title: "9. User-Generated Content",
    content: [
      "9.1 You are solely responsible for any content you upload, post, or share on the Platform.",
      "9.2 By submitting content, you grant Clutch IT a license to use, store, and process it as necessary for the operation of the Platform.",
      "9.3 Clutch IT may review or remove content that violates these Terms or applicable laws."
    ]
  },
  {
    title: "10. Termination",
    content: [
      "10.1 By You: You may terminate your use of the Services at any time by providing written notice to Clutch IT.",
      "10.2 By Us: Clutch IT may suspend or terminate your access for violation of these Terms, non-payment, or other valid reasons.",
      "10.3 Upon termination, your access to the Platform will cease, and any outstanding fees will remain payable."
    ]
  },
  {
    title: "11. Third-Party Integrations",
    content: [
      "11.1 The Platform may integrate with or link to third-party services.",
      "11.2 Clutch IT is not responsible for the content, availability, or performance of third-party services. Your use of such services is at your own risk."
    ]
  },
  {
    title: "12. Feedback and Suggestions",
    content: [
      "12.1 Any feedback or suggestions you provide regarding the Services may be used by Clutch IT without compensation or obligation to you.",
      "12.2 Clutch IT retains all rights to develop products or features based on your feedback."
    ]
  },
  {
    title: "13. Safety and Compliance",
    content: [
      "13.1 Users must ensure their safety and the safety of others while using the Platform.",
      "13.2 Clutch IT disclaims liability for any harm or damages resulting from improper use of the Platform."
    ]
  },
  {
    title: "14. Governing Law and Dispute Resolution",
    content: [
      "14.1 These Terms are governed by the laws of Australia.",
      "14.2 Any disputes arising under these Terms shall be resolved exclusively in the courts of the State/Territory of registration."
    ]
  },
  {
    title: "15. General Provisions",
    content: [
      "15.1 If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions shall remain in effect.",
      "15.2 These Terms constitute the entire agreement between you and Clutch IT regarding the use of the Services."
    ]
  },
  {
    title: "16. User Accounts",
    content: [
      "16.1 Responsibility for Account Security: Users are responsible for maintaining the confidentiality of their login credentials. Any activity that occurs under your account is your responsibility.",
      "16.2 Accurate Information: You must provide accurate, complete, and up-to-date information for your account.",
      "16.3 Unauthorized Access: You are responsible for any unauthorized access or use of your account. To protect your account, ensure that your login credentials remain secure. Clutch IT is not liable for any loss or damage arising from your failure to maintain account security."
    ]
  },
  {
    title: "17. Acknowledgment of Use",
    content: [
      "17.1 Authorized Access: You confirm that you are authorized to use the Platform and access information and data entered by you or by users you have authorized.",
      "17.2 Third-Party Use: If you access the Platform on behalf of others (individuals or organizations):",
      "• You are responsible for ensuring you have the necessary rights and authorization.",
      "• You must manage access permissions for authorized users, and Clutch IT will only act based on your instructions.",
      "17.3 Indemnification: You agree to indemnify Clutch IT for any claims or losses resulting from:",
      "• Clutch IT's refusal to grant unauthorized access to your data.",
      "• Clutch IT's provision of access to data upon your authorization."
    ]
  },
  {
    title: "18. No Warranties",
    content: [
      "18.1 No Guarantee of Suitability: Clutch IT makes no guarantees that the Platform will meet your requirements or be suitable for any specific purpose.",
      "18.2 Exclusion of Implied Warranties: To the extent permitted by law, all implied warranties, including but not limited to merchantability, fitness for a particular purpose, title, and non-infringement, are expressly excluded."
    ]
  },
  {
    title: "19. Termination of Service",
    content: [
      "19.1 Right to Terminate: Clutch IT reserves the right to suspend or terminate your access to the Platform without prior notice for reasons including, but not limited to, breach of these Terms.",
      "19.2 User-Initiated Termination: You may terminate your use of the Platform at any time by deleting your account.",
      "19.3 Trial Period: Users may evaluate the Platform during a defined trial period. If you choose not to continue after the trial, you must notify Clutch IT to delete your organization's data."
    ]
  },
  {
    title: "20. Breach and Remedies",
    content: [
      "20.1 Breach Remediation: If you breach these Terms, Clutch IT may:",
      "• Suspend or terminate your access to the Platform.",
      "• Suspend access to your data.",
      "20.2 Non-Payment: Failure to pay fees by the due date may result in suspension or termination of your access and rights.",
      "20.3 Accrued Rights: Termination does not affect rights or obligations accrued before the termination date. You remain liable for any outstanding fees."
    ]
  },
  {
    title: "21. Severability",
    content: [
      "21.1 Partial Invalidity: If any provision of these Terms is deemed invalid or unenforceable, it will be replaced with a provision that closely reflects the original intent, and the remaining Terms will remain in effect."
    ]
  },
  {
    title: "22. Governing Law and Jurisdiction",
    content: [
      "22.1 Jurisdiction by Region: These Terms are governed by the laws of your region based on the residency of the primary data holder:",
      "• Australia: If the primary data holder resides in Australia, the laws of the State of registration apply.",
      "• International: For users outside of Australia, Clutch IT may elect the jurisdiction of a specific region or international arbitration."
    ]
  }
];

const TermsOfUse = () => {
  return (
    <section className="min-h-screen flex flex-col items-center bg-gray-100 py-28 px-8">
      <motion.div
        className="max-w-4xl w-full text-left"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-3xl font-bold mb-4">Terms of Use</h1>
        <h2 className="text-2xl font-semibold mb-3">CLUTCH IT TERMS OF SERVICE</h2>
        <p className="text-sm text-gray-600 mb-4">Last Updated: December 18th, 2024</p>
        
        <p className="text-base text-gray-700 mb-6">
          Welcome to Clutch IT, a platform owned and operated by MAHONYS SOFTWARE BD PTY LTD ("Clutch IT," "we," "our," or "us"). By accessing or using our software, mobile applications, and related services ("the Platform" or "Services"), you agree to be bound by these Terms of Service ("Terms").
        </p>

        <p className="text-base text-gray-700 mb-8">
          If you do not agree to these Terms, you must not use the Platform. These Terms form a binding agreement between you and Clutch IT.
        </p>

        <div className="space-y-8">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg p-6 shadow-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * index }}
            >
              <h3 className="text-xl font-semibold mb-4 text-gray-800">
                {section.title}
              </h3>
              <div className="space-y-3">
                {Array.isArray(section.content) ? (
                  section.content.map((item, i) => (
                    <div key={i} className="text-sm text-gray-700">
                      {item.startsWith('•') ? (
                        <div className="ml-4 flex">
                          <span className="mr-2">•</span>
                          <span>{item.substring(2)}</span>
                        </div>
                      ) : (
                        <p>{item}</p>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-sm text-gray-700">{section.content}</p>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        <div className="mt-8 text-sm text-gray-600 text-center">
          <p>For any questions about these Terms, please contact us at support@clutchit.com</p>
        </div>
      </motion.div>
    </section>
  );
};

export default TermsOfUse; 