import React from 'react';
import { motion as m } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const privacyPolicyData = {
  lastUpdated: "December 18th, 2024",
  introduction: {
    text: "This privacy notice for Clutch IT, a trading name of MAHONYS SOFTWARE BD PTY LTD (we, us or our', describes how and why we may collect, store, use, and/or share (process) your information when you use our services (Services), such as when you:",
    servicesList: [
      "Access or use any of our software solutions or applications (e.g., Clutch Logistics, Clutch Booking, Clutch Compliance).",
      "Engage with us in related activities, including sales, marketing, customer support, or events."
    ],
    contactInfo: "If you have any questions or concerns, this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you have further questions or concerns, please contact us at seydussaqline@clutchit.com.au."
  },
  keyPoints: {
    title: "SUMMARY OF KEY POINTS",
    description: "This summary highlights key points from our privacy notice, but you can find more detailed information in the full document below.",
    points: [
      {
        title: "What personal information do we process?",
        content: "We may process personal information depending on your interaction with our Services. Learn more about the personal information we collect here."
      },
      {
        title: "Do we process sensitive personal information?",
        content: "We do not process sensitive personal information."
      },
      {
        title: "Do we receive any information from third parties?",
        content: "We do not receive any information from third parties."
      },
      {
        title: "How do we process your information?",
        content: "We process your information to deliver, improve, and manage our Services, communicate with you, ensure security, prevent fraud, and comply with the law."
      },
      {
        title: "How do we share personal information?",
        content: "We may share your information in specific situations and with certain third parties. Learn more about sharing personal information here."
      },
      {
        title: "How do we keep your information safe?",
        content: "We implement organizational and technical measures to safeguard your personal information. However, no online communication or storage method is entirely secure, so we cannot guarantee that hackers or unauthorized third parties will not access or alter your information."
      },
      {
        title: "What are your rights?",
        content: "Depending on your location, privacy laws may grant you certain rights regarding your personal information. Learn more about your rights here."
      },
      {
        title: "How do you exercise your rights?",
        content: "You can exercise your rights by contacting us or visiting our website at www.clutchit.com.au."
      }
    ]
  },
  tableOfContents: [
    "What Information Do We Collect?",
    "How Do We Process Your Information?",
    "When and With Whom Do We Share Your Personal Information?",
    "Do We Use Cookies and Other Tracking Technologies?",
    "How Long Do We Keep Your Information?",
    "How Do We Keep Your Information Safe?",
    "Do We Collect Information from Minors?",
    "What Are Your Privacy Rights?",
    "Controls for Do-Not-Track Features",
    "Do California Residents Have Specific Privacy Rights?",
    "Do We Make Updates to This Notice?",
    "How Can You Contact Us About This Notice?",
    "How Can You Review, Update, or Delete the Data We Collect from You?"
  ],
  sections: [
    {
      title: "WHAT INFORMATION DO WE COLLECT?",
      subsections: [
        {
          title: "Personal Information You Disclose to Us",
          shortDescription: "In Short: We collect personal information that you voluntarily provide to us.",
          content: "We collect personal information when you register for or use our Services, express interest in our products or services, participate in activities on our Services, or communicate with us directly. The personal information we collect may include:",
          list: [
            "Full Name",
            "Email Address",
            "Phone Number",
            "Mailing Address",
            "Username and Password",
            "Billing Address",
            "User Preferences"
          ]
        },
        {
          title: "Sensitive Information",
          content: "We do not process sensitive personal information."
        },
        {
          title: "Application Data",
          content: "If you use our software applications, we may collect the following data if you grant us permission:",
          list: [
            "Geolocation Information: We may request access to location-based data from your mobile device or web browser to offer location-specific features of our Services.",
            "Push Notifications: We may send notifications related to your account or Service updates. You can opt-out from these notifications in your device settings."
          ],
          additionalInfo: "This data is used to ensure proper operation of our Services, improve user experience, and assist in troubleshooting."
        },
        {
          title: "Accuracy of Personal Information",
          content: "All personal information provided must be accurate, complete, and up to date. If there are changes to your information, you should inform us promptly."
        }
      ]
    },
    {
      title: "HOW DO WE PROCESS YOUR INFORMATION?",
      content: "We process your information to:",
      list: [
        "Provide and improve our Services",
        "Communicate with you, including customer support and notifications",
        "Ensure security and prevent fraud",
        "Comply with legal obligations",
        "Process payments for our subscription-based services"
      ],
      additionalInfo: "We only process your personal information when we have a valid legal basis to do so, such as with your consent, to perform a contract with you, or to comply with legal obligations."
    },
    {
      title: "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
      content: "We may share personal information with:",
      list: [
        "Service Providers: To help with our Services, such as hosting, payment processing, and analytics.",
        "Business Partners: For joint marketing and service offerings.",
        "Legal Compliance: If required by law, such as to respond to subpoenas or other legal processes."
      ],
      additionalInfo: "We do not sell your personal information to third parties."
    },
    {
      title: "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
      content: "Yes, we use cookies and similar technologies to enhance your experience on our Services. You can manage your cookie preferences in your browser settings."
    },
    {
      title: "HOW LONG DO WE KEEP YOUR INFORMATION?",
      content: "We retain personal information for as long as necessary to fulfill the purposes for which it was collected, including any legal, accounting, or reporting requirements."
    },
    {
      title: "HOW DO WE KEEP YOUR INFORMATION SAFE?",
      content: "We implement a range of security measures to protect your personal information. However, no system is entirely secure, and we cannot guarantee that unauthorized access will not occur."
    },
    {
      title: "WHAT ARE YOUR PRIVACY RIGHTS?",
      content: "You may have rights under privacy laws depending on your jurisdiction, such as the right to access, correct, or delete your personal information."
    },
    {
      title: "CONTROLS FOR DO-NOT-TRACK FEATURES",
      content: "Certain browsers have a Do Not Track feature that allows you to signal your preference not to be tracked online. We currently do not respond to Do Not Track signals."
    },
    {
      title: "DO WE MAKE UPDATES TO THIS NOTICE?",
      content: "We may update this privacy notice from time to time. Any changes will be posted on this page with the updated date."
    },
    {
      title: "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
      content: "For any questions or concerns, please contact us at:",
      contactDetails: {
        email: "seydussaqline@clutchit.com.au",
        phone: "+61426627645",
        address: "115-117 William Angliss Drive, Laverton North, VIC, 3026"
      }
    },
    {
      title: "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
      content: "You can review or request updates to your personal information by logging into your account or contacting us directly."
    }
  ]
};

const PrivacyPolicy = () => {
  const renderContactDetails = (details) => (
    <div className="ml-4 space-y-1 text-gray-600">
      <p>Email: {details.email}</p>
      <p>Phone: {details.phone}</p>
      <p>Address: {details.address}</p>
    </div>
  );

  const renderList = (list) => (
    <ul className="list-disc pl-6 mb-4 space-y-2 text-gray-600">
      {list.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );

  const renderSection = (section) => (
    <div key={section.title} className="mb-10">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">{section.title}</h2>
      
      {section.subsections ? (
        section.subsections.map((subsection, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">{subsection.title}</h3>
            {subsection.shortDescription && (
              <p className="italic mb-3 text-gray-600">{subsection.shortDescription}</p>
            )}
            {subsection.content && <p className="mb-4 text-gray-600">{subsection.content}</p>}
            {subsection.list && renderList(subsection.list)}
            {subsection.additionalInfo && (
              <p className="mt-4 text-gray-600">{subsection.additionalInfo}</p>
            )}
          </div>
        ))
      ) : (
        <>
          {section.content && <p className="mb-4 text-gray-600">{section.content}</p>}
          {section.list && renderList(section.list)}
          {section.additionalInfo && <p className="mt-4 text-gray-600">{section.additionalInfo}</p>}
          {section.contactDetails && renderContactDetails(section.contactDetails)}
        </>
      )}
    </div>
  );

  return (
   <section className="min-h-screen flex flex-col items-center bg-gray-100 py-28 px-8">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <m.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-10"
          >
            <h1 className="text-3xl font-bold text-gray-800 mb-3">
              Privacy Policy
            </h1>
            
          </m.div>

          <div className="prose max-w-none">
            {/* Last Updated */}
            <p className="text-base text-gray-500 mb-8">
              Last updated: {privacyPolicyData.lastUpdated}
            </p>

            {/* Introduction */}
            <div className="mb-10">
              <p className="mb-4 text-gray-600">{privacyPolicyData.introduction.text}</p>
              {renderList(privacyPolicyData.introduction.servicesList)}
              <p className="mb-6 text-gray-600">{privacyPolicyData.introduction.contactInfo}</p>
            </div>

            <div className="border-t border-gray-200 my-8"></div>

            {/* Key Points */}
            <div className="mb-10">
              <h2 className="text-xl font-semibold mb-3 text-gray-800">
                {privacyPolicyData.keyPoints.title}
              </h2>
              <p className="mb-6 text-gray-600">{privacyPolicyData.keyPoints.description}</p>
              <div className="space-y-5">
                {privacyPolicyData.keyPoints.points.map((point, index) => (
                  <div key={index}>
                    <h3 className="text-lg font-semibold mb-2 text-gray-700">{point.title}</h3>
                    <p className="text-gray-600">{point.content}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="border-t border-gray-200 my-8"></div>

            {/* Table of Contents */}
            <div className="mb-10">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">TABLE OF CONTENTS</h2>
              <ol className="list-decimal pl-6 space-y-2 text-gray-600">
                {privacyPolicyData.tableOfContents.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            </div>

            <div className="border-t border-gray-200 my-8"></div>

            {/* Main Sections */}
            {privacyPolicyData.sections.map((section) => (
              <React.Fragment key={section.title}>
                {renderSection(section)}
                <div className="border-t border-gray-200 my-8"></div>
              </React.Fragment>
            ))}
          </div>
        </div>
    </section>
  );
};

export default PrivacyPolicy; 