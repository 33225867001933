import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { motion as m } from 'framer-motion';

const Contact = () => {
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/xldevoov", {
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setStatus("Thanks for your submission!");
        form.reset();
      } else {
        const data = await response.json();
        if (data.errors) {
          setStatus(data.errors.map((error) => error.message).join(", "));
        } else {
          setStatus("Oops! There was a problem submitting your form");
        }
      }
    } catch (error) {
      setStatus("Oops! There was a problem submitting your form");
    }
  };


  return (
    <section className="section-padding pt-28">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Get in Touch</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Ready to start your next project? Contact us for a free consultation
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-8"
          >
            <div className="flex flex-col space-y-8">
            <div className="flex items-start space-x-4">
                <MapPinIcon className="h-6 w-6 text-accent flex-shrink-0" />
                <div>
                  <h3 className="font-bold mb-1">Our Location (Australia)</h3>
                  <p className="text-gray-600">115-117 William Angliss Drive, Laverton North,Victoria, 3026</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <MapPinIcon className="h-6 w-6 text-accent flex-shrink-0" />
                <div>
                  <h3 className="font-bold mb-1">Our Location (Bangladesh)</h3>
                  <p className="text-gray-600">K.M. TOWER (4th Floor), Agrabad C/A, Chattogram-4100, Bangladesh.</p>
                </div>
              </div>
              
            </div>
            
            <div className="flex items-start space-x-4">
              <EnvelopeIcon className="h-6 w-6 text-accent flex-shrink-0" />
              <div>
                <h3 className="font-bold mb-1">Email Us</h3>
                <p className="text-gray-600">client-support@clutchit.com.au</p>
              </div>
            </div>
            
            <div className="flex items-start space-x-4">
              <PhoneIcon className="h-6 w-6 text-accent flex-shrink-0" />
              <div>
                <h3 className="font-bold mb-1">Call Us</h3>
                <p className="text-gray-600">+61426627645</p>
                <p className="text-gray-600">+61438925410</p>
              </div>
            </div>
          </motion.div>

          <m.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="max-w-3xl mx-auto"
        >
          <form onSubmit={handleSubmit} className="flex-grow flex flex-col">
            <div className="grid grid-cols-2 gap-6 mb-6">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                  First name *
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="w-full border border-gray-300 rounded-lg px-3 py-2 text-base focus:outline-none focus:ring-2 focus:ring-accent/20 focus:border-accent"
                  required
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                  Last name *
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="w-full border border-gray-300 rounded-lg px-3 py-2 text-base focus:outline-none focus:ring-2 focus:ring-accent/20 focus:border-accent"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Your email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border border-gray-300 rounded-lg px-3 py-2 text-base focus:outline-none focus:ring-2 focus:ring-accent/20 focus:border-accent"
                  required
                />
              </div>
              <div>
                <label htmlFor="mobile" className="block text-sm font-medium text-gray-700 mb-1">
                  Mobile
                </label>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  className="w-full border border-gray-300 rounded-lg px-3 py-2 text-base focus:outline-none focus:ring-2 focus:ring-accent/20 focus:border-accent"
                />
              </div>
            </div>
            <div className="mb-6">
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="w-full border border-gray-300 rounded-lg px-3 py-2 text-base focus:outline-none focus:ring-2 focus:ring-accent/20 focus:border-accent"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                Message *
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="w-full border border-gray-300 rounded-lg px-3 py-2 text-base focus:outline-none focus:ring-2 focus:ring-accent/20 focus:border-accent"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn-primary w-full"
            >
              Send Message
            </button>
            {status && (
              <p className="mt-3 text-sm text-green-600 text-center">{status}</p>
            )}
          </form>
        </m.div>
        </div>
      </div>
    </section>
  );
};

export default Contact; 