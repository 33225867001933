import React from 'react';
import { motion } from 'framer-motion';
import Testimonials from './Testimonials';


const Hero = () => {

  return (
    <>
    <section className="relative min-h-screen flex items-center justify-center px-4 sm:px-2 lg:px-3 pt-24 md:pt-0">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-[0.8fr,1.2fr] gap-14 items-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-left"
        >
          <h1 className="text-5xl md:text-6xl font-bold leading-tight flex flex-col">
            Powering
            <span className="text-accent"> Smart Logistics</span>
          </h1>
          <p className="text-gray-600 text-lg mb-8 pt-2">
          We deliver intelligent transport management software, seamless booking platforms, and compliance management tools to empower agricultural businesses with
           efficient and sustainable transport solutions.
          </p>
          
          <div className="flex flex-wrap gap-4">
            <a href="https://clutch-support.atlassian.net/servicedesk/customer/portal/7/group/13/create/43" target="_blank" rel="noopener noreferrer">
            <button 
              className="btn-primary"
            >
              Unlock 14-Day Access
            </button>
            </a>
            <a href="https://clutch-support.atlassian.net/servicedesk/customer/portal/7/group/13/create/44" target="_blank" rel="noopener noreferrer">
            <button 
              className="px-6 py-3 border-2 border-primary rounded-lg hover:bg-primary hover:text-white transition-all"
            >
              Request a Demo
            </button>
            </a>
          </div>
          
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, x: 100, rotate: -5 }}
          animate={{ opacity: 1, x: 0, rotate: 0 }}
          transition={{ 
            duration: 0.8, 
            type: "spring",
            bounce: 0.4
          }}
          className="relative perspective-1000 flex justify-center items-center"
        >
          <motion.img
            src="/images/truck-clutch.jpg"
            alt="Blue Truck"
            className="w-[80%] h-[80%] object-cover"
            initial={{ scale: 1.1, rotateY: -15 }}
            animate={{ scale: 1, rotateY: 0 }}
            transition={{ 
              duration: 1,
              type: "spring",
              stiffness: 100
            }}
            whileHover={{ 
              scale: 1.05, 
              rotateY: 5,
              transition: { duration: 0.3 }
            }}
          />
        </motion.div>
      </div>
      
    </section>
    <Testimonials />  
    </>
  );
};

export default Hero;