import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebook, FaTwitter } from 'react-icons/fa';

const Footer = () => {

  return (
    <footer className="bg-gray-500 text-white rounded-t-xl">

      {/* Links Section */}
      <div className="max-w-7xl mx-auto px-6 pt-8 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* About Column */}
        <div>
          <h3 className="text-blue-400 mb-4">About</h3>
          <ul className="space-y-2">
            <li><Link to="/about" className="hover:text-blue-400">Meet the Team</Link></li>
           
          </ul>
        </div>

        {/* Clutch IT Column */}
        <div>
          <h3 className="text-blue-400 mb-4">Clutch IT</h3>
          <ul className="space-y-2">
            <li><Link to="/services" className="hover:text-blue-400">Services</Link></li>
            <li><Link to="/support" className="hover:text-blue-400">Support</Link></li>
            <li><Link to="/contact" className="hover:text-blue-400">Contact Us</Link></li>
          </ul>
        </div>

        {/* Contact Column */}
        <div>
          <div className="mb-8">
            <h3 className="text-blue-400 mb-4">Our Location</h3>
            <div className="space-y-4">
              
              <div>
                <p>115-117 William Angliss Drive,</p>
                <p>Laverton North, Victoria, 3026</p>
              </div>
              <div>
                <p>K.M. TOWER (4th Floor), Agrabad C/A,</p>
                <p>Chattogram-4100, Bangladesh</p>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-blue-400 mb-4">Contact</h3>
            <p>+61426627645</p>
            <p>+61438925410</p>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="max-w-7xl mx-auto px-6 py-4 border-b border-white"></div>
      <div className="max-w-7xl mx-auto px-6 py-4 border-t border-gray-500 flex justify-between items-center">
        
        <Link to="/" className="text-2xl font-bold">Clutch.</Link>
        <div className="max-w-7xl mx-auto px-6 py-4 flex justify-center space-x-4">
        <a href='/terms-of-use' target="_blank" rel="noopener noreferrer" ><h1 className="hover:text-blue-400">Terms of Use</h1></a>
        <a href='/privacy-policy' target="_blank" rel="noopener noreferrer" ><h1 className="hover:text-blue-400">Privacy Policy</h1></a>



      </div>
        <div className="flex gap-4">
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" 
             className="hover:text-blue-400 transition-colors">
            <FaLinkedin size={20} />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" 
             className="hover:text-blue-400 transition-colors">
            <FaFacebook size={20} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" 
             className="hover:text-blue-400 transition-colors">
            <FaTwitter size={20} />
          </a>
        </div>
      </div>

      {/* Terms and Privacy Links */}
      
    </footer>
  );
};

export default Footer; 