import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const Users = [
  {
    name: "Exporters",
    logo: "/images/exporter.png",
    "features": [
      {
        "title": "Order Tracking",
        "description": "Monitor transport orders in real-time to ensure deadlines are met."
      },
      {
        "title": "Load Management",
        "description": "Efficiently allocate loads to transport assets, optimizing schedules and reducing delays."
      },
      {
        "title": "Integrated Compliance",
        "description": "Stay compliant with logistics regulations by tracking checklist completions and managing mass and fatigue compliance."
      },
      {
        "title": "Performance Insights",
        "description": "Gain actionable data on transport performance and bottlenecks to improve operational efficiency."
      }
    ]
  },
  {
    name: "Logistics",
    logo: "/images/logistics_truck.png",
    "features": [
      {
        "title": "Asset Management",
        "description": "Track and maintain transport assets, including schedules for servicing and compliance."
      },
      {
        "title": "Transport Scheduling",
        "description": "Plan and manage deliveries to align with customer deadlines and site availability."
      },
      {
        "title": "Compliance Tracking",
        "description": "Ensure regulatory compliance with built-in tools for pre-starts, inductions, and incident reporting."
      },
      {
        "title": "Driver & Equipment Logs",
        "description": "Centralize data on driver hours, equipment usage, and fault reports."
      },
      {
        "title": "Custom Reporting",
        "description": "Generate detailed reports to analyze operations and support decision-making."
      }
    ]
  },
  {
    name: "Receival Site",
    logo: "/images/site.png",
    "features": [
      {
        "title": "Slot Booking System",
        "description": "Allow transport providers to book categorized drop-off or pick-up slots based on availability."
      },
      {
        "title": "Traffic Management",
        "description": "Monitor and control site traffic flow to avoid congestion and improve safety."
      },
      {
        "title": "Commodity Tracking",
        "description": "Organize and manage commodity receivals efficiently with real-time updates."
      },
      {
        "title": "Customizable Time Slots",
        "description": "Configure booking slots to meet specific site and commodity requirements."
      },
      {
        "title": "Data Integration",
        "description": "Seamlessly connect with exporters and logistics providers for a unified workflow."
      }
    ]
  }
];

const UsersComponent = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSelectedUser(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleButtonClick = (index) => {
    setSelectedUser((prevSelectedUser) => (prevSelectedUser === index ? null : index));
  };

  return (
    <section className="pt-20 px-4 sm:px-6 lg:px-8" >
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Discover how Clutch IT can empower your business!</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Your all-in-one solution for seamless transport, logistics, and site management.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {Users.map((user, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="transition-all text-center"
            >
              <img
                src={user.logo}
                alt={user.name}
                className="h-20 w-auto mx-auto mb-4 grayscale hover:grayscale-0 hover:filter hover:brightness-110 transition-all"
                ref={containerRef}
              />
              <button
                className="btn-primary px-4 py-3 rounded-lg hover:bg-primary hover:text-white transition-all"
                onClick={() => handleButtonClick(index)}
                
              >
                {user.name}
              </button>
              {selectedUser === index && (
                <div className="mt-4 flex flex-wrap justify-center">
                  {user.features.map((feature, featureIndex) => (
                    <motion.div
                      key={featureIndex}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: featureIndex * 0.1, duration: 0.5 }}
                      className="mb-4 w-64 bg-black-600 shadow-lg rounded-lg p-4"
                    >
                      <h3 className="text-xl font-semibold text-accent">{feature.title}</h3>
                      <p className="text-black-600">{feature.description}</p>
                    </motion.div>
                  ))}
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UsersComponent; 