import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Logistics from './pages/Logistics';
import Bookings from './pages/Bookings';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import SupportPage from './pages/Support';
import TermsOfUse from './pages/TermsOfUse';

import PrivacyPolicy from './pages/PrivacyPolicy';
function App() {
  return (
    <Router>

      <ScrollToTop />
      <div className="min-h-screen bg-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>

    </Router>
  );
}

export default App;
